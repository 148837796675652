import React from "react";

const Footer = () => {
    const year = new Date().getFullYear();

    return (
        < div
            name="footer"
            className="w-full h-min	 bg-gradient-to-b from-gray-800 to-black text-white px-4"
        >
            <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full">
                <div className="pb-8">
                    <p className="text-1xl font-bold inline border-b-4 border-gray-500">
                        <footer>{`©${year} vylwyn.com`}</footer>
                    </p>
                </div>
            </div>

        </div >

    )

};

export default Footer;